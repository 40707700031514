@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic00,900italic);

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: "Gilroy", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html, body{
    scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
